
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from './cell'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'

@Component
  export default class ActionCell extends Cell {
  @Prop({ type: Boolean, default: true }) displaySpanName!: boolean
  declare options: LinkedCellOptions

  loadingButton = false

  get action () {
    return this.options?.action
  }

  get contact () {
    const { item } = this

    if (!item) return undefined
    const { contact } = this

    return contact
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'findLite', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
    })
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        _and: [
          { id_version_year: { _eq: auto.version.id } },
          { component: { slug: { _in: ['bodywork', 'fuel', 'transmission', 'traction'] } } },
        ],
      },
    })

    if (attributes?.length) {
      return attributes
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        return generation?.attributes || []
      } else {
        cont++
      }
    }

    return generation?.attributes || []
  }

  async trigger (value) {
    const { action, item, options, process } = this
    this.loadingButton = true
    const trigger = action?.trigger || item?.trigger

    if (trigger) {
      if (options?.action?.messaging) {
        const system = (await this.fetchData({
          query: { name: 'find', model: 'Person' },
          filter: { type: { name: { _eq: 'system' } } },
        }))[0]

        if (value?.autoStock) {
          value.autoStock.attributes = await this.findAlternativesAttributes(value.autoStock)

          const stockDetail = (await this.fetchData({
            query: { name: 'find', model: 'StockViewDetails' },
            filter: { id_auto: { _eq: value.autoStock.id } },
          }))[0]
          value.autoStock.dealAutoAttribute = stockDetail.attributes
        }

        if (value?.appraisalAuto) {
          value.appraisalAuto.attributes = await this.findAlternativesAttributes(value.appraisalAuto)
          value.appraisalAuto.dealAutoAttribute = await this.fetchData({
            query: { name: 'find', model: 'DealAutoAttribute', order: { id: 'desc' } },
            filter: { _and: [{ deal: { id_auto: { _eq: value.appraisalAuto.id } } }, { component: { slug: { _eq: 'mileage' } } }] },
          })
        }

        this.loadingButton = false
        return trigger(value, process?.configMessage, system?.alias)
      } else {
        this.loadingButton = false
        return trigger(value)
      }
    }
  }

  get position () {
    const { item } = this
    if (!item) return 'top'
    const { position = 'top' } = item
    return position
  }

  get top () {
    const { position } = this

    return position === 'top'
  }

  get left () {
    const { position } = this

    return position === 'left'
  }

  get disabledButton () {
    const { options, item, disabled: disabledBtn } = this

    if (disabledBtn || !item) return true

    if (typeof item === 'string' && item.length) return false
    if (typeof item === 'number' && !isNaN(item)) return false
    if (item && 'disabled' in item) {
      const { disabled } = item

      return disabled
    }
    if (!options) {
      return false
    }

    const { options: { disabled, disabledMissingProperty, disabledActionMissingProperty }, process } = this

    if (disabledActionMissingProperty && options?.action?.messaging && process?.configMessage?.messaging) {
      const paths = process?.configMessage?.messaging[disabledActionMissingProperty.key]?.phone

      if (Array.isArray(paths)) {
        // Itera sobre cada path y verifica si al menos uno contiene un valor válido
        return paths.some(path => this.isMissingPathProperty(path, item))
      }

      // Si paths no es un array, trata el valor como un único path
      return this.isMissingPathProperty(paths, item)
    }

    return disabled || this.isMissingProperty(disabledMissingProperty, item)
  }

  get displayName () {
    const { action, displaySpanName } = this
    return action && !action.only && displaySpanName
  }
  }
